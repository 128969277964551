import { createContext, PropsWithChildren, useContext, useState } from 'react';

type SidebarContextType = {
  showMainSidebar: boolean;
  setShowMainSidebar: (show: boolean) => void;
  isSidebarOpen: boolean;
  setSidebarOpen: (show: boolean) => void;
};

const SidebarContext = createContext<SidebarContextType>({
  setShowMainSidebar: () => null,
  showMainSidebar: true,
  isSidebarOpen: true,
  setSidebarOpen: () => null,
});

const SidebarProvider = ({ children }: PropsWithChildren) => {
  const [showSidebar, setShowSidebar] = useState<boolean>(true);
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(true);

  return (
    <SidebarContext.Provider
      value={{ showMainSidebar: showSidebar, setShowMainSidebar: setShowSidebar, isSidebarOpen, setSidebarOpen }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

const useSidebarContext = () => {
  return useContext(SidebarContext);
};

export { SidebarProvider, useSidebarContext };

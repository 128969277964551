import { ComponentTheme } from '.';

export const feedbackTheme: ComponentTheme = {
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: '16px 24px',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: ({ theme }) => ({
        background: theme.palette.background.paper,
        borderRadius: 5,
        boxShadow: '0px 4px 5px 0px rgba(63, 113, 45, 0.08)',
      }),
    },
  },
};

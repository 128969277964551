import { Components, Theme } from '@mui/material';
import { dataDisplayTheme } from './data-display.theme';
import { feedbackTheme } from './feedback.theme';
import { inputsTheme } from './inputs.theme';

export * from './typography.theme';

export type ComponentTheme = Components<Omit<Theme, 'components'>>;

export const components: ComponentTheme = {
  ...dataDisplayTheme,
  ...feedbackTheme,
  ...inputsTheme,
  MuiCssBaseline: {
    styleOverrides: (theme) => ({
      ':root, :before, :after': {
        '--primary-color': `${theme.palette.primary.main} !important`,
      },
      '.truncate': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      '.phone-input-large, .phone-input-middle': {
        '.react-tel-input': {
          'input.form-control': {
            padding: '16px 14px 16px 58px!important',
          },
        },
      },
      '.phone-input-small': {
        '.react-tel-input': {
          'input.form-control': {
            padding: '9px 14px 7px 58px!important',
          },
        },
      },
      '.react-tel-input': {
        '.special-label': {
          fontSize: '11px!important',
        },
      },
    }),
  },
};

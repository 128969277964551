import { THEMES } from '@dfl/mui-react-common';
import { colors, createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { components, typography } from './themes';

export const RED = '#d32f2f';
export const GREEN = '#65BE46';
export const WARNING = colors.orange['500'];

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }

  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }
}

// Extend color prop on components
declare module '@mui/material/Chip' {
  export interface ChipPropsColorOverrides {
    opacity: true;
  }
}
// #fdfdfd59

export const common = createTheme({
  components,
  typography,
});

const LIGHT = {
  ...common,
  palette: {
    mode: THEMES.LIGHT,
    sidebar: {
      background: '#fff',
      color: 'secondary.main',
      activeColor: 'secondary.main',
      active: 'primary.light',
    },
    spaceSelector: '#f3f4f9',
    primary: {
      main: '#72B62E',
      light: '#d9efd1',
      contrastText: '#fff',
    },
    opacity: {
      main: '#fdfdfd59',
      contrastText: '#fff',
    },
    success: {
      main: '#65BE46',
      contrastText: '#fff',
    },
    secondary: {
      ...grey,
      main: grey['900'],
      light: grey['400'],
    },
    neutral: {
      main: '#F4F5F6',
    },
    background: {
      default: '#F3F5F7',
      paper: '#fff',
    },
    formLabel: '#000000de',
    error: {
      main: '#F84842',
      contrastText: '#fff',
    },
    warning: {
      main: '#FDA20A',
      contrastText: '#fff',
    },
    info: {
      main: '#37AEFF',
      light: '#37AEFF',
      contrastText: '#fff',
    },
  },
};

const DARK = {
  ...common,
  palette: {
    mode: THEMES.DARK,
    sidebar: '#111827',
    spaceSelector: '#222b36',
    background: {
      default: '#1e2732',
      paper: '#222b36',
    },
    primary: {
      main: '#90caf9', // '#a4328a',
      light: '#363e48',
      contrastText: '#fff',
    },
    opacity: {
      main: '#fdfdfd59',
      contrastText: '#fff',
    },
    secondary: {
      ...grey,
      main: grey['400'],
      light: grey['800'],
    },
    neutral: {
      main: grey['800'],
    },
    formLabel: '#fff',
    link: '#8ab4f8',
  },
};

export const THEME_SETTING = {
  [THEMES.LIGHT]: LIGHT,
  [THEMES.DARK]: DARK,
};

import { ComponentTheme } from '.';

export const inputsTheme: ComponentTheme = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontWeight: 'normal',
      },
    },
    variants: [
      {
        props: { variant: 'contained' },
        style: ({ theme: { palette } }) => ({
          color: palette.primary.contrastText,
        }),
      },
      {
        props: { variant: 'contained', color: 'success' },
        style: ({ theme: { palette } }) => ({
          backgroundColor: palette.success.main,
          color: palette.success.contrastText,
          '&:hover': {
            color: palette.success.contrastText,
            backgroundColor: palette.success.light,
          },
        }),
      },
    ],
  },
  MuiToggleButton: {
    styleOverrides: {
      root: ({ theme: { palette } }) => ({
        '&.Mui-selected': {
          backgroundColor: palette.primary.main,
          color: palette.background.paper,
          '&:hover': { backgroundColor: palette.primary.main, color: palette.primary.contrastText },
        },
      }),
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        // '.MuiInputBase-input:not(.MuiInputBase-inputSizeSmall)': { padding: '13px 14px;' },
      },
    },
  },
};
